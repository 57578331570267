<template>
  <main id="content" role="main">

    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden" style="background-color: #F0F8FD;">
      <div class="container space-top-3 space-bottom-2 mt-lg-5">
        <div class="w-lg-60 text-center mx-lg-auto">
          <div class="mb-5">
            <h1 class="mb-3">beNovelty Events</h1>
            <p class="lead">Join us for inspirational conferences, programmes and more.</p>
          </div>
        </div>
      </div>

      <!-- ========== Event listing Section ========== -->
      <div class="container space-bottom-2">
        <input v-for="item in category_list" :id="item.category" :key="item.category" v-model="selected_category" type="radio" name="categories" :value="item.category">
        <ol class="filters-btn" style="margin-left: 1rem; overflow: auto; white-space: nowrap; -webkit-overflow-scrolling: touch;">
          <li v-for="item in category_list" :key="item.category">
            <label class="btn btn-sm btn-soft-secondary btn-pill py-2 px-3 m-1" :class="{'selectedCategory': selected_category === item.category}" :for="item.category">{{ item.category_title }}</label>
          </li>
        </ol>
        <ol class="filters-container" style="padding: 0 0.5rem 0.5rem 0.5rem">
          <div class="row">
            <li v-for="item in event_list" :key="item.url" class="col-md-6 col-lg-4 mb-5 filters-item" :data-category="item.category">
              <ResourcesCard :item="item" />
            </li>
          </div>
        </ol>
      </div>
      <!-- ========== End Event Listing Section ========== -->

      <!-- SVG Background Shape -->
      <figure class="w-35 position-absolute top-0 right-0 z-index-n1 mt-n11 mr-n11">
        <img class="img-fluid" src="@/assets/svg/components/half-rhombus-1.svg">
      </figure>

      <figure class="w-25 position-absolute bottom-0 left-0 z-index-n1 mb-n11 ml-n11">
        <img class="img-fluid" src="@/assets/svg/components/half-rhombus-2.svg">
      </figure>
      <!-- End SVG Background Shape -->
    </div>

  </main>
</template>

<script>
import ResourcesCard from '@/components/resources-card'
import { mapGetters } from 'vuex'
export default {
  name: 'Events',
  components: {
    ResourcesCard
  },
  data() {
    return {
      category_list: [],
      event_list: [],
      selected_category: 'All'
    }
  },
  computed: {
    ...mapGetters([
      'events'
    ])
  },
  created() {
    this.event_list = this.$store.getters.events.event_list
    this.getCategoryList(this.event_list)
  },
  methods: {
    getCategoryList(list) {
      function compare(a, b) {
        // Use toUpperCase() to ignore character casing
        const titleA = a.category_title.toUpperCase()
        const titleB = b.category_title.toUpperCase()

        let comparison = 0
        if (titleA > titleB) {
          comparison = 1
        } else if (titleA < titleB) {
          comparison = -1
        }
        return comparison
      }
      let array = []
      const temp = []
      for (let i = 0; i < list.length; i++) {
        const object = {
          category_title: '',
          category: ''
        }
        object.category_title = list[i].category_title
        object.category = list[i].category
        array = array.concat(object)
      }
      const map = new Map()
      for (const item of array) {
        if (!map.has(item.category_title)) {
          map.set(item.category_title, true)
          temp.push({
            category_title: item.category_title,
            category: item.category
          })
        }
      }
      temp.sort(compare)
      this.category_list = [{ category_title: 'All', category: 'All' }]
      this.category_list = this.category_list.concat(temp)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Events | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/events' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Events | beNovelty' },
        { property: 'og:description', content: 'Join Us for Inspirational Conferences, Programmes and More.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/events' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
